.selection-table th {
  border-bottom: 1px solid #ccc;
  border-collapse: collapse;
  padding: 0.25rem 0.6rem;
  text-align: center;
}

.selection-table td {
  border-collapse: collapse;
  padding: 0.3rem 0.6rem;
  text-align: left;
  max-width: 100px;
  min-width: fit-content;
  white-space: normal;
}

.selection-table tr td:first-child {
  border-collapse: collapse;
  padding: 0.3rem 0.6rem;
  text-align: center;
}

.selection-table thead th:first-child {
  border-collapse: collapse;
  padding: 0.4rem 0.6rem;
  text-align: center;
}

.selection-table tr:nth-child(even) {
  background-color: #f2f2f2;
}
